import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Animation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CanvasAnimation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/footers/Footer14.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/footers/MobileFooter2.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/headers/Header14.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/loader/Loader.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/MobileAnimation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/modals/NewsLetter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
